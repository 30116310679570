import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { getSanitizedMarkup } from '../helpers.js';


class ScanningTheGuardianAdditionalContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
    }
  }
  render() {
    const file = "/images/gallery/" + this.props.image.file;
    return (
      <div>
        <div className="related-object">
          <Modal
            show={this.state.modalOpen}
            keyboard={true}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <span className="image-title-period">{this.props.image.period}</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-7">
                  <div className="row">
                    <div className="col-lg-4 offset-lg-4">
                      <iframe className="scanning-iframe" width="700" title="Scanning The Guardian 1" height="300" src="https://www.youtube.com/embed/SykPl0HTZIw?controls=0&rel=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen ></iframe>
                    </div>
                  </div>
                  <div className="row margin-top-2">
                    <div className="col-lg-4">
                      <iframe className="scanning-iframe" title="Scaning the Guardian 2" width="300" height="300" src="https://www.youtube.com/embed/zxnhpYoiz_U?controls=0&rel=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div className="col-lg-4 d-none d-md-block">
                      <div className="d-none d-lg-block ct-scans-container">
                        <div className="ct-scans-details">
                          CT Scans Reveal Wood Grain of Statue
                        </div>
                        <div className="ct-scans-directions row no-gutters">
                          <div className="col-lg-6 left">side view</div>
                          <div className="col-lg-6 right">top view</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <iframe className="scanning-iframe" title="Scanning The Guardian 3" width="700" height="300" src="https://www.youtube.com/embed/e2TYcA5nDYU?controls=0&rel=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <h4>{this.props.image.title}</h4>
                  <div className="margin-top-2" dangerouslySetInnerHTML={getSanitizedMarkup(this.props.image.description)}></div>
                  <div className="additional-image-details" dangerouslySetInnerHTML={getSanitizedMarkup(this.props.image.image_details)}></div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <button className="link-button" onClick={this.handleOpen}>
            <img src={file} width="250" alt=""></img>
            <i className="fa fa-info"></i>
          </button>
        </div>
        <div className="additional-content-title">
          {this.props.image.title}
        </div>
      </div>
    )

  }
  handleOpen = () => {
    this.setState({
      modalOpen: true,
    });
    this.props.darkenOrLigthenFirstModal(true);
  }

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
    this.props.darkenOrLigthenFirstModal(false);
  }


}

export default ScanningTheGuardianAdditionalContent;