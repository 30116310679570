import React, { Component } from "react";
import ReactCompareImage from 'react-compare-image';

class PreservationTreatment extends Component {
  render() {
    return (
      <div className="container PreservationTreatment">
        <h1 className="page-title">Compare pre- and post- preservation treatment</h1>
        <div className="row">
          <div className="col-lg-3">
            <h4>Slide vertical divider left-right</h4>
            <div className="preservation-treatment-piece-info">
              <p>
                Kim Hongdo (Dan Won)<br />
                    Korean, 1745-c.1806<br />
                <strong>Hunting with Falcons</strong><br />
                    Joseon Dynasty (1392-1910),<br />
                    Late 18th Century, c. 1792-1795<br />
                    Ink and color on silk<br />
                    Gift of General James A. Van Fleet
                </p>
            </div>
          </div>
          <div className="col-lg-6 margin-bottom-2">
            <ReactCompareImage
              leftImage={'/images/conservation/Before1988.2.25F2a_cropped.png'}
              rightImage={'/images/conservation/After1988.2.25F2a_cropped.png'}
            />
          </div>
          <div className="col-lg-3">
            <h4 className="margin-top-2-mobile">"Hunting with Falcons" Preservation Treatment Report</h4>
            <iframe
              className="falcons-iframe"
              title="Hunting With Falcolns"
              allow="fullscreen"
              src="//e.issuu.com/embed.html?d=artpreservationreport-huntingwithfalcons&u=harnmuseumofart">
            </iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default PreservationTreatment;