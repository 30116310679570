import React, { Component } from "react";
import {
  GlassMagnifier,
  TOUCH_ACTIVATION
} from "react-image-magnifiers";

class PostPreservationDetails extends Component {
  render() {
    return (
      <div className="container">
        <h1 className="page-title">View details of post-preservation treatment</h1>
        <div className="row">
          <div className="col-lg-6">
            <div className="row row-mt-3">
              <div className="col-lg-6">
                <div className="post-preservation-other-images">
                  <img src="/images/conservation/IMG_6296.jpg" alt="" className="max-width100 post-pres-img" />
                  <img src="/images/conservation/IMG_6248.jpg" alt="People at a table" className="max-width100 post-pres-image" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="post-preservation-credits">
                  <p>
                    Kim Eunho<br />
                    Korean, 1892-1979<br />
                    <strong>Folk Dancers Dressed as Buddhist Nuns</strong><br />
                    Japanese Occupation of Korea (1910-1945), 1922<br />
                    Ink and color on silk <br />
                    Gift of General James A. Fleet<br />
                    1988.1.28
                  </p>
                </div>
                <div className="preservation-pdf">
                  <h4 className="margin-top-2-mobile">"Folk Dancers Dressed as Buddhist Nuns" Preservation Treatment Report</h4>
                  <iframe
                  className="folk-dancers-iframe"
                    title="Folk Dancers dressed as nuns"
                    allowfullscreen
                    allow="fullscreen"
                    src="//e.issuu.com/embed.html?d=artpreservationreport-folkdancers&u=harnmuseumofart">
                  </iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <GlassMagnifier
              imageSrc="/images/conservation/1988.1.28F2b_50.png"
              imageAlt="Conservation Image"
              largeImageSrc="/images/conservation/1988.1.28F2b_98.png"
              square={true}
              allowOverflow={false}
              touchActivation={TOUCH_ACTIVATION.TAP}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default PostPreservationDetails;
