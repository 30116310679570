import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from "./components/Home";
import Galleries from "./components/Galleries";
import Gallery from "./components/Gallery";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import PreservationTreatment from "./components/PreservationTreatment";
import Conservation from "./components/Conservation";
import PostPreservationDetails from "./components/PostPreservationDetails";



// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function KoreanArtGallery() {
  const path = window.location.pathname;
  const isGalleryPath = path.indexOf('/galleries') > -1;
  const isConservationPath = path.indexOf('/conservation') > -1;
  return (
    <Router>
      <main>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Navbar.Brand className="brand-image" href="https://harn.ufl.edu"><img className="harn-logo" src="/images/logo.png" alt="Harn Logo" width="330" height="115"></img></Navbar.Brand>
          <Navbar.Brand className="brand-title mobile" href="/">Explore Korean Art and Culture</Navbar.Brand>
          <Navbar.Brand className="brand-title desk" href="/">Explore Korean Art and Culture</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/galleries" active={isGalleryPath}>Timeline of Art</Nav.Link>
              <Nav.Link href="/conservation" active={isConservationPath}>Conservation</Nav.Link>
              <Nav.Link href="mailto:knmappsupport@harn.ufl.edu" active={false}>Feedback</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <hr />

        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/galleries" component={Galleries}></Route>
          <Route path="/galleries/:name" component={Gallery} />
          <Route exact path="/conservation" component={Conservation}></Route>
          <Route exact path="/conservation/preservation-treatment" component={PreservationTreatment}></Route>
          <Route exact path="/conservation/post-preservation-details" component={PostPreservationDetails}></Route>

        </Switch>
      </main>
    </Router>
  );
}
