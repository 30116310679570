import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import RelatedObject from "./RelatedObject";
import { getSanitizedMarkup } from '../helpers.js';
import AdditionalContent from "./AdditionalContent";
import ScanningTheGuardianAdditionalContent from "./ScanningTheGuardianAdditionalContent";


class GalleryImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      obscureModal: false,
    }
  }
  render() {
    const file = "/images/gallery/" + this.props.image.file;
    const relatedObjects = this.props.image.related_objects;
    const hasRelatedObjects = relatedObjects && relatedObjects.length > 0;
    const additionalContent = this.props.image.additional_content;
    const useScanningTheGuardianTemplate = additionalContent && additionalContent[0].use_scanning_guardian_template;
    const hasAdditionalContent = additionalContent && additionalContent.length > 0;
    const secondFile = this.props.image.second_file;
    const secondFilePath = "/images/gallery/" + secondFile;

    return (
      <div className="gallery-image">
        <Modal
          show={this.state.modalOpen}
          keyboard={true}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="image-title-period">{this.props.period}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={this.state.obscureModal ? 'obscure' : ''}>
            <div className="row">
              <div className="col-lg-5">
                {!secondFile &&
                  <div className="col-lg-12">
                    <img src={file} width="100%" alt={this.props.image.title}></img>
                    <a href={file}>View Full Image</a>
                  </div>
                }
                {secondFile &&
                  <div className="row">
                    <div className="col-lg-5">
                      <img src={file} width="100%" alt={this.props.image.title}></img>
                      <a href={file}>View Full Image</a>
                    </div>
                    <div className="col-lg-5">
                      <img src={secondFilePath} width="100%" alt={this.props.image.title + " supplmental image"}></img>
                      <a href={secondFile}>View Full Image</a>
                    </div>
                  </div>
                }
              </div>
              <div className="col-lg-7">
                <div className="row full-height">
                  <div className="col-lg-6">
                    <h4>{this.props.image.title}</h4>
                    <div className="image-details">
                      <span dangerouslySetInnerHTML={getSanitizedMarkup(this.props.image.region)}></span><br />
                      <span dangerouslySetInnerHTML={getSanitizedMarkup(this.props.image.period)}></span><br />
                      {this.props.image.type}<br />
                      {this.props.image.gift_info}<br />
                      {/* {this.props.image.piece_code} */}
                    </div>
                    <div className="image-description" dangerouslySetInnerHTML={getSanitizedMarkup(this.props.image.piece_description)}></div>
                  </div>
                  <div className="col-lg-6 related-column">
                    <div className="additional-content">
                      {/* Standard Template */}
                      {
                        hasAdditionalContent && !useScanningTheGuardianTemplate &&
                        <AdditionalContent
                          image={additionalContent[0]}
                          darkenOrLigthenFirstModal={this.darkenOrLigtenModalBackground}
                        >
                        </AdditionalContent>
                      }
                      {/* One-off scanning the guardian. Could probably refactor to include HTML in additional_content data*/}
                      {
                        hasAdditionalContent && useScanningTheGuardianTemplate &&
                        <ScanningTheGuardianAdditionalContent
                          image={additionalContent[0]}
                          darkenOrLigthenFirstModal={this.darkenOrLigtenModalBackground}
                        >
                        </ScanningTheGuardianAdditionalContent>
                      }
                    </div>
                    {(hasRelatedObjects) &&
                      <div className="related-content">
                        <h4>Related Object</h4>
                        {hasRelatedObjects &&
                          <RelatedObject
                            image={relatedObjects[0]}
                            period={this.props.period}
                            darkenOrLigthenFirstModal={this.darkenOrLigtenModalBackground}>
                          </RelatedObject>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <button className="link-button" onClick={this.handleOpen} >
          <img src={file} width="250" alt=""></img>
          <i className="fa fa-info"></i>
        </button>
      </div>
    )
  }
  handleOpen = () =>
    this.setState({
      modalOpen: true,
    });

  handleClose = () =>
    this.setState({
      modalOpen: false,
    });

  darkenOrLigtenModalBackground = (val) => {
    this.setState({
      obscureModal: val,
    });

  }
}

export default GalleryImage;
