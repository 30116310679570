import DOMPurify from 'dompurify'

/**
 * 
 * @param {string} markup  Unsanitized markup
 * @return string          Sanitized markup
 */
export function getSanitizedMarkup(markup) {
    const sanitized= DOMPurify.sanitize(markup);

    return {__html: sanitized};
}
