import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

class Conservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConservationModal: false,
      showHangingScrollModal: false,
      showRemountingModal: false,
    }
  }

  handleClose = () =>
    this.setState({
      showConservationModal: false,
      showHangingScrollModal: false,
      showRemountingModal: false,
    });

  showConservation = () =>
    this.setState({
      showConservationModal: true,
    });

  showHangingScroll = () =>
    this.setState({
      showHangingScrollModal: true,
    });

  showRemounting = () =>
    this.setState({
      showRemountingModal: true,
    });


  render() {
    return (
      <div className="Conservation">
        <div className="container">
          <h1 className="page-title">Today (Conservation)</h1>
          <div className="row">
            <div className="col-lg-6">
              <p>An important part of the mission of the Samuel P. Harn Museum of Art is to care for and preserve the collection for future generations. Since the opening of the David A. Cofrin Asian Art Wing in 2012, 11 Korean objects have been undergone <button className="link-button" onClick={this.showConservation}>conservation</button> treatment or are in the process of being conserved.</p>
              <p>Each object, whether a <button className="link-button" onClick={this.showHangingScroll}>hanging scroll</button> or a sutra page, had distinctly different condition issues that prevented it from being safely exhibited before conservation treatment. The hanging scrolls in this exhibition that have been treated are the Kim Eun-ho and the Kim Hong-do. Each of these has undergone the complicated process of <button className="link-button" onClick={this.showRemounting}>remounting</button>, and can now be safely displayed.</p>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">
                  <Link to="/conservation/preservation-treatment"><img className="max-width100" src="/images/conservation/After1988.1.25F2a.jpg" alt="Conservation piece on scroll" /></Link>
                </div>
                <div className="col-lg-6">
                  <Link to="/conservation/post-preservation-details"><img className="max-width100" src="/images/conservation/1988.1.28F2b_50.png" alt="Conservation piece full" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Conservation */}
        <Modal show={this.state.showConservationModal} onHide={this.handleClose} dialogClassName="conservation-modal">
          <Modal.Header closeButton>
            <Modal.Title>What is Conservation?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Conservation includes any steps taken to minimize deterioration of any object through time with as little change to the object as possible. These steps can be preventative (controlling light, temperature and humidity) or interventive (cleaning, stabilizing, and repairing or replacing elements). </p>
          </Modal.Body>
        </Modal>

        {/* Hanging Scroll*/}
        <Modal show={this.state.showHangingScrollModal} onHide={this.handleClose} dialogClassName="conservation-modal">
          <Modal.Header closeButton>
            <Modal.Title>What is a Hanging Scroll?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Simply described, an Asian painting or calligraphy is a thin layer of pigments adhered with animal glue to a flexible support of paper or silk. Typically, silk fabrics chosen to surround the central "image" create a protective and decorative border. These elements are lined with several layers of paper and wheat starch adhesive and are combined to create an overall supporting structure. This integral mounting structure allows a painting to be "opened" for display and "closed" for storage.</p>
          </Modal.Body>
        </Modal>

        {/* Remounting Scroll*/}
        <Modal show={this.state.showRemountingModal} onHide={this.handleClose} dialogClassName="conservation-modal">
          <Modal.Header closeButton>
            <Modal.Title>What is Remounting?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Remounting is defined as the process of detaching, repairing and reattaching a surface to its support; in the case of a hanging scroll, a painting made of paper or silk is detached from silk borders, and its paper backing is repaired and reattached to new silk borders and fresh paper backing.</p>
          </Modal.Body>
        </Modal>

      </div>
    )
  }
}

export default Conservation;
