import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { getSanitizedMarkup } from '../helpers.js';


class RelatedObject extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
    }
  }
  render() {
    const file = "/images/gallery/" + this.props.image.file;
    const logoPath = this.props.image.logo ? "/images/" + this.props.image.logo : false;
    return (
      <div className="related-object">
        <Modal
          show={this.state.modalOpen}
          keyboard={true}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="row">
                <div className="col-lg-5">
                  Related Object
                </div>
                <div className="col-lg-2">
                  {logoPath &&
                    <img className="related-image-logo" src={logoPath} alt="Museum Affiliation Logo" />
                  }
                </div>

                {/* Used period from parent component */}
                <div className="col-lg-5">
                  <span className="image-title-period">{this.props.period}</span>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="related-content-region"
                  dangerouslySetInnerHTML={getSanitizedMarkup(this.props.image.region)}>
                </div>
                <div className="related-content-title"><strong>{this.props.image.title}</strong></div><br />
                <span dangerouslySetInnerHTML={getSanitizedMarkup(this.props.image.period)}></span><br />
                {this.props.image.type}<br />
                {this.props.image.dimensions}
                <br />
                <br />
                <span dangerouslySetInnerHTML={getSanitizedMarkup(this.props.image.collection)}></span><br />
                {this.props.image.accession_number}<br />
              </div>
              <div className="col-lg-6">
                <img src={file} width="100%" alt={this.props.image.title}></img>
                <div className="row">
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <button className="link-button" onClick={this.handleOpen}>
          <img src={file} width="250" alt="" ></img>
          <i className="fa fa-info"></i>
        </button>
      </div>
    )
  }
  handleOpen = () => {
    this.setState({
      modalOpen: true,
    });
    this.props.darkenOrLigthenFirstModal(true);
  }

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
    this.props.darkenOrLigthenFirstModal(false);
  }

}

export default RelatedObject;