import React from "react";
import { Link } from "react-router-dom";
import '../galleries.css';


const Galleries = () => (
    <div className="container-fluid Galleries">
        <h1 className="page-title">Timeline of Korean Art</h1>
        <section className="ps-timeline-sec">
            <div className="container">
                <ol className="ps-timeline">
                    <li>
                        <div className="img-handler-top img-handler">
                            <Link to="/galleries/three-kingdoms"><img className="gallery-image" src="/images/timeline/3-Kingdoms-timeline.jpg" width="150" alt="" /></Link>
                            <div className="gallery-period-description">
                                <Link to="/galleries/three-kingdoms">
                                    Three Kingdoms (57 BCE - 668 CE)
                                </Link>
                            </div>
                        </div>
                        <span className="ps-sp-top"></span>
                    </li>
                    <li>
                        <div className="img-handler-bot img-handler">
                            <Link to="/galleries/unified-silla"><img className="gallery-image" src="/images/timeline/Unified-Silla-Timeline.jpg" width="150" alt="" /></Link>
                            <div className="gallery-period-description">
                                <Link to="/galleries/unified-silla">
                                    Unified Silla (668 - 935)
                                </Link>
                            </div>
                        </div>
                        <span className="ps-sp-bot"></span>
                    </li>
                    <li>
                        <div className="img-handler-top img-handler">
                            <Link to="/galleries/goryeo"><img className="gallery-image" src="/images/timeline/Goryeo-Timeline.jpg" width="150" alt="" /></Link>
                            <div className="gallery-period-description">
                                <Link to="/galleries/goryeo">
                                    Goryeo (918 - 1392)
                                </Link>
                            </div>
                        </div>
                        <span className="ps-sp-top"></span>
                    </li>
                    <li>
                        <div className="img-handler-bot img-handler">
                            <Link to="/galleries/joseon-dynasty"><img className="gallery-image" src="/images/timeline/Joseon-Timeline.jpg" width="150" alt="" /></Link>
                            <div className="gallery-period-description">
                                <Link to="/galleries/joseon-dynasty">
                                    Joseon Dynasty (1392 - 1910)
                                </Link>
                            </div>
                        </div>
                        <span className="ps-sp-bot"></span>
                    </li>
                    <li>
                        <div className="img-handler-top img-handler">
                            <Link to="/galleries/20th-century"><img className="gallery-image" src="/images/timeline/20th-Century-Timeline.jpg" width="150" alt="" /></Link>
                            <div className="gallery-period-description">
                                <Link to="/galleries/20th-century">
                                    20th Century
                                </Link>
                            </div>
                        </div>
                        <span className="ps-sp-top"></span>
                    </li>
                    <li>
                        <div className="img-handler-bot img-handler">
                            <Link to="/conservation"><img className="gallery-image" src="/images/timeline/Conservation-Today-Timeline.jpg" width="150" alt="" /></Link>
                            <div className="gallery-period-description">
                                <Link to="/conservation">
                                Today <div className="small-text">(Conservation)</div>
                                </Link>
                            </div>
                        </div>
                        <span className="ps-sp-bot"></span>
                    </li>
                </ol>
            </div>
        </section>
        <section className="ps-timeline-sec-mobile">
            <div className="container">
                {/* Three Kingdoms Mobile */}
                <div className="row">
                    <div className="col-xs-6 desc-row">
                        <div className="mobile-gallery-description">
                            <Link to="/galleries/three-kingdoms">
                                Three Kingdoms (57 BCE - 668 CE)
                                </Link>
                        </div>
                    </div>
                    <div className="col-xs-6 img-row">
                        <Link to="/galleries/three-kingdoms"><img className="max-width100" src="/images/timeline/3-Kingdoms-timeline.jpg" width="150" alt="" /></Link>
                    </div>
                </div>
                {/* Unified Sila Mobile */}
                <div className="row">
                    <div className="col-xs-6 desc-row">
                        <div className="mobile-gallery-description">
                            <Link to="/galleries/unified-silla">
                                Unified Silla (668 - 935)
                                </Link>
                        </div>
                    </div>
                    <div className="col-xs-6 img-row">
                        <Link to="/galleries/unified-silla"><img className="max-width100" src="/images/timeline/Unified-Silla-Timeline.jpg" width="150" alt="" /></Link>
                    </div>
                </div>
                {/* Goryeo Mobile */}
                <div className="row">
                    <div className="col-xs-6 desc-row">
                        <div className="mobile-gallery-description">
                            <Link to="/galleries/goryeo">
                                Goryeo (918 - 1392)
                                </Link>
                        </div>
                    </div>
                    <div className="col-xs-6 img-row">
                        <Link to="/galleries/goryeo"><img className="max-width100" src="/images/timeline/Goryeo-Timeline.jpg" width="150" alt="" /></Link>
                    </div>

                </div>
                {/* Joseon Mobile */}
                <div className="row">
                    <div className="row-xs-6 desc-row">
                        <div className="mobile-gallery-description">
                            <Link to="/galleries/joseon-dynasty">
                                Joseon Dynasty (1392 - 1910)
                            </Link>
                        </div>
                    </div>
                    <div className="col-xs-6 img-row">
                        <Link to="/galleries/joseon-dynasty"><img className="max-wdith100" src="/images/timeline/Joseon-Timeline.jpg" width="150" alt="" /></Link>
                    </div>
                </div>
                {/* 20 Century Mobile  */}
                <div className="row">
                    <div className="row-xs-6 desc-row">
                        <div className="mobile-gallery-description">
                            <Link to="/galleries/20th-century">
                                20th Century
                            </Link>
                        </div>
                    </div>
                    <div className="col-xs-6 img-row">
                        <Link to="/galleries/20th-century"><img className="max-width100" src="/images/timeline/20th-Century-Timeline.jpg" width="150" alt="" /></Link>
                    </div>
                </div>
                {/* Conservation Mobile  */}
                <div className="row">
                    <div className="row-xs-6 desc-row">
                        <div className="mobile-gallery-description">
                            <Link to="/conservation">
                                Today (Conservation)
                                </Link>
                        </div>
                    </div>
                    <div className="col-xs-6 img-row">
                        <Link to="/conservation"><img className="max-width100" src="/images/timeline/Conservation-Today-Timeline.jpg" width="150" alt="" /></Link>
                    </div>
                </div>
            </div>
        </section>
    </div >
);

export default Galleries;