import React, { Component } from "react";
import imageData from "../data/image-data.json";
import GalleryImage from "./GalleryImage";
import { getSanitizedMarkup } from "../helpers";

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryInfo: {
        title_long: "",
        title_short: "",
        desciption: "",
        images: [],
        numColumns: null,
      },
    };
  }
  componentDidMount() {
    const { name } = this.props.match.params;
    this.setState({
      shortName: name,
    })
    this.fetchGalleryData(name);
  }

  render() {
    if (!Number.isInteger(this.state.galleryInfo.numColumns)) {
      return <div />
    }
    return (
      <div className="container">
        <h1 className="page-title">{this.state.galleryInfo.title_long}</h1>
        <div className="row">
          <div className="col-lg-4" dangerouslySetInnerHTML={getSanitizedMarkup(this.state.galleryInfo.description)}></div>
          <div className="col-lg-8">{this.getImageMarkup(this.state.galleryInfo.numColumns)}</div>
        </div>
      </div>
    )
  }

  /**
   * Fetch data from the json data map
   * @param {string} name 
   * @returns void
   */
  fetchGalleryData(name) {
    const data = imageData[name];
    const titleLong = data['title_long']
    const titleShort = data['title_short']
    const description = data['description']
    const images = data['images'].sort((a, b) => parseInt(a.order) - parseInt(b.order));
    const numColumns = data['num_columns'];

    this.setState({
      galleryInfo: {
        title_long: titleLong,
        title_short: titleShort,
        description: description,
        images: images,
        numColumns: numColumns,
      },
    })
  }
  /**
   * 
   * @param {int} numColumns 
   */
  getImageMarkup(numColumns) {
    console.log(numColumns);
    const rows = this.state.galleryInfo.images.reduce(function (rows, key, index) {
      return (index % numColumns === 0 ? rows.push([key])
        : rows[rows.length - 1].push(key)) && rows;
    }, []);

    return rows.map((rowData, index) => {
      const columnClass = numColumns === 2 ? "col-lg-6" : "col-lg-4";
      return (
        <div className="row">
          <div className={columnClass} key={index}>
            {/* {rowData[0].internal_code} */}
            <GalleryImage image={rowData[0]} key={rowData[0].file} period={this.state.galleryInfo.title_short} />
          </div>
          {/* Include the second column only if it exists */}
          {rowData[1] &&
            <div className={columnClass} key={index}>
              {/* {rowData[1].internal_code} */}
              <GalleryImage image={rowData[1]} key={rowData[1].file} period={this.state.galleryInfo.title_short} />
            </div>}
          {/* Include the third column only if it exists */}
          {rowData[2] &&
            <div className={columnClass} key={index}>
              {/* {rowData[2].internal_code} */}
              <GalleryImage image={rowData[2]} key={rowData[2].file} period={this.state.galleryInfo.title_short} />
            </div>}
        </div>
      );
    });
  }
}

export default Gallery;