import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showScrollInfo: false,
    }
  }

  handleClose = () =>
    this.setState({
      showScrollInfo: false,
    });

  showScrollInfo = () =>
    this.setState({
      showScrollInfo: true,
    });

  render() {
    return (
      <div className="container Home">
        <h1 className="page-title">Explore Korean Art and Culture</h1>
        <div className="row">
          <div className="col col-lg-12">
            <div className="view-galleries"><a href="/galleries">Explore Now</a></div>
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/images/home/1988.1.25D2e_scroll_resized.jpg"
                  alt="Landscape scene"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/images/gallery/NEW_D2o_2018.65.jpg"
                  alt="People with book scence"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/images/home/1988.1.28-034E2o_scroll_resized.jpg"
                  alt="Quilt"
                />
              </Carousel.Item>
            </Carousel>
            <div className="exhibition-credit">
              <p>In 2012, the Harn Museum of Art opened its only gallery dedicated to the arts of a single nation which was supported 
                by the Korea Foundation. The recent renovation of the south gallery in the David A. Cofrin Asian Art Wing is supported 
                by a generous grant from the National Museum of Korea and re-opened in Summer 2020.</p>
              <p>This website was created to accompany the exhibition <em>Everyday to the Extraordinary: Highlights from the
                  Korean Collection</em>, which opened to the public at the Harn Museum of Art August 27, 2020. </p>
              <p>This exhibition is made possible by the generous support of the National Museum of Korea with
                additional support from the Cofrin Curator of Asian Art Endowment.</p>
              <p>All Harn collection photography in this online experience by Randy Batista.</p>
            </div>
          </div>
          {/* <div className="col col-lg-3 home-right">
            <div className="positioner">
              <img className='max-width100' src="/images/home/Arts flyer hanging scroll 1 .png" alt="" />
              <button className="link-button info-button" onClick={this.showScrollInfo}><i className="fa fa-info"></i> Info</button>
            </div>
          </div> */}
        </div>
        {/* Scroll Info*/}
        <Modal show={this.state.showScrollInfo} onHide={this.handleClose} dialogClassName="conservation-modal">
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <p>
              Jang Seung'eop (1843-1897)<br />
              <em>Scholars in a Garden</em>, 19th Century<br />
              Hanging scroll, ink and colors on silk<br />
              Collection Harn Museum of Art<br />
            </p>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Home;