import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { getSanitizedMarkup } from '../helpers.js';


class AdditionalContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
    }
  }
  render() {
    const file = "/images/gallery/" + this.props.image.file;
    const logoPath = this.props.image.logo ? "/images/" + this.props.image.logo : false;

    return (
      <div>
        <div className="related-object">
          <Modal
            show={this.state.modalOpen}
            keyboard={true}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <div className="row">
                  <div className="col-lg-2 offset-lg-5">
                    {logoPath &&
                      <img className="related-image-logo" src={logoPath} alt="Museum Affiliation Logo" />
                    }
                  </div>
                  <div className="col-lg-5">
                    <span className="image-title-period">{this.props.image.period}</span>
                  </div>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-6">
                  <img src={file} width="100%" alt={this.props.image.title}></img>
                </div>
                <div className="col-lg-6">
                  <h4>{this.props.image.title}</h4>
                  <div className="additional-image-details" dangerouslySetInnerHTML={getSanitizedMarkup(this.props.image.image_details)}></div>
                  <div dangerouslySetInnerHTML={getSanitizedMarkup(this.props.image.description)}></div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <button className="link-button" onClick={this.handleOpen}>
            <img src={file} width="250" alt=""></img>
            <i className="fa fa-info"></i>
          </button>
        </div>
        <div className="additional-content-title">
          {this.props.image.title}
        </div>
      </div>
    )

  }
  handleOpen = () => {
    this.setState({
      modalOpen: true,
    });
    this.props.darkenOrLigthenFirstModal(true);
  }

  handleClose = () => {
    this.setState({
      modalOpen: false,
    });
    this.props.darkenOrLigthenFirstModal(false);
  }


}

export default AdditionalContent;